<template>
  <div class="total">
    <div class="inviteHead"></div>
    <div class="main">
      <div class="lose-efficacy flex-column justify-around">
        <a-spin :spinning="spinning">
          <a-form
            class="user-layout-login"
            :model="formState"
            @finish="handleFinish"
          >
            <div class="logo">
              <img src="@/assets/logo2.png" />
            </div>
            <div class="remark">加入成功，等待审核</div>
            <div class="failedInfo">
              你已完成加入{{
                invitation.invitationEnterprise
              }}的申请。管理员审批通过后，你将收到短信/邮件通知，敬请留意。
            </div>
            <a-button
              type="primary"
              size="large"
              block
              html-type="submit"
              class="login-button"
              @click="backLogin"
            >
              打开微信小程序
            </a-button>
          </a-form>
        </a-spin>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
// import { LockOutlined, MobileOutlined, MailOutlined, UserOutlined } from '@ant-design/icons-vue'
// import ProtocolView from '@/components/ProtocolView'
import api from '@/api/API'
import axios from 'axios'
// const userId = '39fc90eb-91d4-2d9c-7efc-f6313d0d1488'

export default defineComponent({
  name: 'account-Success',
  components: {
    // UserOutlined,
    // LockOutlined,
    // MobileOutlined,
    // MailOutlined,
    // ProtocolView,
  },
  data () {
    return {
      spinning: false, // 是否加载
      wxUrl: '', // 跳转链接
      invitation: {
        invitationEnterprise: null,
      },
    }
  },
  created () {
    const query = this.$route.query
    this.invitation.invitationEnterprise = query.invitationEnterprise
    // this.invitationVerification()
  },
  methods: {
    // 确认加入
    handleFinish () {
      this.spinning = true
      const obj = {
        userName: this.formState.name,
      }
      api
        .post('/api/app/employee-invitation/accept-invitation-and-regist', obj)
        .then(({ data }) => {
          this.spinning = false
          this.$message.success('正在跳转')
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 返回登录页
    backLogin () {
      const tokenObj = {
        grant_type: 'client_credential',
        appid: 'APPID',
        secret: 'APPSECRET',
      }
      api
        .get('/api/app/employee-invitation/jump-link')
        .then(({ data }) => {
          this.wxUrl = data
          window.location.href = this.wxUrl
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
      // this.$router.push({ path: '/account/login' })
    },
  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.total {
  background: rgb(233, 233, 233);
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.inviteHead {
  height: 120px;
  font-size: 18px;
  text-align: center;
  line-height: 100px;
  font-family: "Microsoft YaHei";
  color: rgb(255, 81, 110);
}
.ant-form-item-control-wrapper {
  width: 100% !important;
}
.remark {
  font-size: 20px;
  margin: 30px 0 60px;
  background: white;
  position: relative;
  font-family: "Microsoft YaHei";
  &:after {
    content: " ";
    width: 40px;
    height: 5px;
    background: #1890ff;
    position: absolute;
    bottom: -10px;
    left: 0%;
  }
}

.failedInfo {
  font-size: 18px;
  margin: 30px 0 60px;
  color: gray;
  background: white;
  position: relative;
  font-family: "Microsoft YaHei";
}

.user-layout-login {
  background: white;
  margin: auto;
  padding: 50px;
  width: 600px;
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .countDownTimer {
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 8px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
.lose-efficacy {
  height: 300px;
  font-size: 25px;
}
</style>
